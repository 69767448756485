
body {
    background-color: #212529;
    color: #212529 !important;
    font-family: "Source Code Pro", monospace;
    word-wrap:break-word;
    font-size:1em;
}

.container {
    max-width:600px;
}
.container-fluid {
    max-width: 600px;
    padding:0;
}

.small-icon {
    width: 35px;
}

@media (max-width: 576px) {
    h1 {
        font-size:1.5em;
    }
}

a {
    color: #212529;
}
    a:hover {
        color: #212529;
        font-weight: bold;
    }

code {
    color: #212529;
}


.btn-primary {
  color: #fff;
  background-color: #1b6ec2;
  border-color: #1861ac;
}
